<template>
  <div
    class="email-item"
    :class="[{ readed: email.seen }, isDraft ? 'email-item--draft' : '']"
  >
    <div class="email-item__action">
      <div class="email-item__checkbox">
        <FveCheckbox
          :field="{
            name: 'email_item',
            model: true,
          }"
          @update:modelValue="$emit('change', $event)"
          :modelValue="!!checked"
        />
      </div>
      <!-- TODO: Вернуть когда появится функционал на бэке -->
      <!-- <div
        class="email-item__star"
        @click="$emit('toggleStarred', !email.isStarred)"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-star'" :class="{ marked: email.isStarred }" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6.286l-1.058 2.006A3 3 0 018.8 9.848l-2.234.386 1.58 1.626a3 3 0 01.818 2.518l-.323 2.245 2.035-1a3 3 0 012.648 0l2.035 1-.323-2.245a3 3 0 01.818-2.518l1.58-1.626-2.234-.386a3 3 0 01-2.142-1.556L12 6.286zm.884-2.61a1 1 0 00-1.768 0L9.173 7.358a1 1 0 01-.714.519l-4.102.71a1 1 0 00-.547 1.682l2.902 2.985a1 1 0 01.273.84l-.593 4.12a1 1 0 001.431 1.04l3.736-1.837a1 1 0 01.882 0l3.736 1.837a1 1 0 001.431-1.04l-.593-4.12a1 1 0 01.273-.84l2.902-2.985a1 1 0 00-.547-1.682l-4.102-.71a1 1 0 01-.714-.519l-1.943-3.682z"/><path d="M11.116 3.676a1 1 0 011.768 0l1.943 3.682a1 1 0 00.714.519l4.102.71a1 1 0 01.547 1.682l-2.902 2.985a1 1 0 00-.273.84l.593 4.12a1 1 0 01-1.431 1.04l-3.736-1.837a1 1 0 00-.882 0l-3.736 1.837a1 1 0 01-1.431-1.04l.593-4.12a1 1 0 00-.273-.84L3.81 10.27a1 1 0 01.547-1.682l4.102-.71a1 1 0 00.714-.519l1.943-3.682z" class="filled"/></svg>
      </div> -->
    </div>
    <div class="email-item__user">
      <router-link
        :to="
          isDraft
            ? { name: $routeName.EMAIL_CREATE, query: { id: email.id } }
            : { name: $routeName.EMAIL_READ, params: { id: email.id } }
        "
        class="email-item__user-name"
      >
        <template v-if="isDraft">Draft</template>
        <template v-else-if="isSentOrSending && email.recipients"
          >To: {{ [].concat(email.recipients).join(', ') }}</template
        >
        <template v-else>
          {{ $user.Profile.getEmail() === email.from ? 'Me' : email.from }}
        </template>
      </router-link>
      <div style="display: flex">
        <div
          v-if="email.ownerCommunityName"
          class="email-item__user-role"
          :style="tagStyle"
        >
          {{ email.ownerCommunityName }}
        </div>
      </div>
    </div>

    <div class="email-item__body">
      <div class="email-item__content">
        <span class="email-item__title">
          <template v-if="email.subject">{{ email.subject }}</template>
          <template v-else>(no subject)</template>
        </span>
        <span
          class="email-item__message"
          v-html="getHtmlTextContent(email.body)"
        ></span>
      </div>

      <div class="email-item__attachments">
        <template v-for="(file, i) in fileList" :key="i">
          <Tag
            class="email-item__attachment"
            :label="file.filename"
            @click="fileStorageGetPreview(file)"
          />
        </template>
        <!-- <div class="attachment">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#E81D1D"/><path d="M8.522 14c.301 0 .563.046.785.137.221.089.393.217.513.386.12.166.18.361.18.587 0 .226-.06.421-.18.587-.12.166-.292.294-.513.386a2.113 2.113 0 01-.785.133h-.58V17H7v-3h1.522zm-.057 1.547c.193 0 .339-.037.437-.111a.39.39 0 00.148-.326.39.39 0 00-.148-.326c-.098-.077-.244-.115-.437-.115h-.523v.878h.523zM10.46 14h1.574c.365 0 .688.061.97.184a1.5 1.5 0 01.657.523c.155.226.233.49.233.793 0 .303-.078.567-.233.793a1.5 1.5 0 01-.657.523 2.408 2.408 0 01-.97.184H10.46v-3zm1.536 2.323c.285 0 .514-.073.685-.219.174-.145.262-.347.262-.604s-.088-.459-.262-.604c-.171-.146-.4-.219-.685-.219h-.594v1.646h.594zM15.33 14.656v.66h1.47v.655h-1.47V17h-.94v-3H17v.656h-1.67z" fill="#fff"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#E81D1D"/></svg>
          <span>presintation.pdf</span>
        </div>

        <div class="attachment">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#505FE1"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#505FE1"/><path d="M7 14.058h1.426c.33 0 .623.059.878.177.256.118.454.286.595.503.14.217.21.47.21.762 0 .291-.07.545-.21.762-.141.217-.34.385-.595.503a2.073 2.073 0 01-.878.177H7v-2.884zm1.391 2.233c.259 0 .465-.07.62-.21.158-.14.237-.334.237-.581 0-.247-.079-.44-.237-.581-.155-.14-.361-.21-.62-.21h-.538v1.582h.538zM12.079 17c-.32 0-.606-.065-.862-.194a1.515 1.515 0 01-.599-.535 1.416 1.416 0 01-.215-.771c0-.286.072-.543.215-.77.147-.229.346-.407.6-.536.255-.13.542-.194.86-.194.32 0 .605.065.858.194.255.129.455.307.598.535.147.228.22.485.22.771 0 .286-.073.543-.22.77a1.478 1.478 0 01-.598.536c-.253.13-.539.194-.857.194zm0-.676c.152 0 .29-.034.413-.103a.761.761 0 00.293-.288.861.861 0 00.107-.433.839.839 0 00-.107-.429.754.754 0 00-.293-.292.837.837 0 00-.414-.103.837.837 0 00-.413.103.754.754 0 00-.293.292.838.838 0 00-.107.429.86.86 0 00.107.433c.072.123.17.22.293.288a.837.837 0 00.414.103zM15.704 17c-.316 0-.6-.063-.853-.19a1.491 1.491 0 01-.59-.535 1.424 1.424 0 01-.215-.775 1.424 1.424 0 01.805-1.306c.252-.13.537-.194.853-.194.275 0 .524.047.745.14.22.093.405.228.551.404l-.543.47a.907.907 0 00-.71-.338.922.922 0 00-.44.103.746.746 0 00-.297.292.866.866 0 00-.103.429.89.89 0 00.103.433c.072.123.171.22.297.288.13.069.276.103.44.103a.907.907 0 00.71-.338l.543.47c-.146.176-.33.31-.551.404-.221.093-.47.14-.745.14z" fill="#fff"/></svg>
          <span>description.doc</span>
        </div>

        <div class="attachment">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#E81D1D"/><path d="M8.522 14c.301 0 .563.046.785.137.221.089.393.217.513.386.12.166.18.361.18.587 0 .226-.06.421-.18.587-.12.166-.292.294-.513.386a2.113 2.113 0 01-.785.133h-.58V17H7v-3h1.522zm-.057 1.547c.193 0 .339-.037.437-.111a.39.39 0 00.148-.326.39.39 0 00-.148-.326c-.098-.077-.244-.115-.437-.115h-.523v.878h.523zM10.46 14h1.574c.365 0 .688.061.97.184a1.5 1.5 0 01.657.523c.155.226.233.49.233.793 0 .303-.078.567-.233.793a1.5 1.5 0 01-.657.523 2.408 2.408 0 01-.97.184H10.46v-3zm1.536 2.323c.285 0 .514-.073.685-.219.174-.145.262-.347.262-.604s-.088-.459-.262-.604c-.171-.146-.4-.219-.685-.219h-.594v1.646h.594zM15.33 14.656v.66h1.47v.655h-1.47V17h-.94v-3H17v.656h-1.67z" fill="#fff"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#E81D1D"/></svg>
          <span>presintation2.pdf</span>
        </div> -->

        <template v-if="email.files?.length > fileCountView && !fileAllShow">
          <div class="attachment-count" @click="fileAllShow = true">
            {{ email.files.length - fileCountView }} more
          </div>
        </template>
      </div>
    </div>

    <div class="email-item__time">{{ prepareDate }}</div>
  </div>
</template>

<script>
import FveCheckbox from '@fve/Switch/FveCheckbox';
import $enum from '@enum';
const { EMAIL_FOLDER_NAME } = $enum;
import getRandomColor from '@helper/getRandomColor';
import getHtmlTextContent from '@helper/getHtmlTextContent';
import Tag from '@component/Tag';
import { fileStorageGetPreview } from '@store/fileStorage.store';
export default {
  name: 'EmailItem',
  components: {
    FveCheckbox,
    Tag,
  },
  props: {
    email: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMarked: false,
      fileCountView: 1,
      fileAllShow: false,
    };
  },
  computed: {
    fileList() {
      return (
        (this.fileAllShow
          ? this.email.files
          : this.email.files?.slice(0, this.fileCountView)) || []
      );
    },
    prepareDate() {
      return DateTime(this.email?.date).format('MM/DD/YY hh:mm A');
    },
    folder() {
      return this.email.folder.toLowerCase();
    },
    isDraft() {
      return this.email.folder === EMAIL_FOLDER_NAME.DRAFT;
    },
    isSentOrSending() {
      return (
        this.email.folder === EMAIL_FOLDER_NAME.SENT ||
        this.email.folder === EMAIL_FOLDER_NAME.SENDING
      );
    },
    tagStyle() {
      const hex = this.getRandomColor(this.email.ownerCommunityName);
      const alpha = 0.125;
      return {
        color: hex,
        backgroundColor: `${hex}${Math.floor(alpha * 255)
          .toString(16)
          .padStart(2, 0)}`,
      };
    },
  },
  methods: {
    getHtmlTextContent,
    getRandomColor,
    fileStorageGetPreview,
  },
};
</script>

<style lang="scss" scoped>
svg {
  fill: var(--color-gray-04);
}

.email-item {
  display: flex;
  padding: 20px 32px;
  background-color: var(--color-gray-01);
  .theme-dark & {
    background-color: var(--search-background);
  }

  .email-item__action {
    margin-right: 20px;
    display: flex;
    flex: 0 0 56px;
  }

  .email-item__attachment {
    cursor: pointer;
    white-space: nowrap;
    margin: 0.25rem 0.5rem 0.5rem;
  }

  .email-item__checkbox,
  .email-item__star {
    max-height: 24px;
    cursor: pointer;
  }

  .email-item__checkbox {
    margin-right: 8px;
  }

  .email-item__user {
    margin-right: 20px;
    flex: 0 0 160px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .email-item__user-name {
      line-height: 1.5;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
      display: inline-block;
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .email-item__user-role {
      display: inline-block;
      padding: 4px 16px;
      line-height: 16px;
      border-radius: 4px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .email-item__body {
    overflow: hidden;
    flex: 1 1 auto;
    padding-right: 20px;
  }

  .email-item__content {
    display: flex;
    margin-bottom: 4px;
    line-height: 1.5;
  }

  .email-item__title {
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
    max-width: 50%; // TODO Определиться со значением
  }

  .email-item__message {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-gray-05);
    max-width: 85%; // TODO Определиться со значением
  }

  .email-item__attachments {
    display: flex;
    align-items: center;
    margin: 0.5rem -0.5rem -0.5rem;
    flex-wrap: wrap;
  }

  .email-item__time {
    color: var(--color-gray-04);
    display: flex;
    flex: 0 0 108px;
    white-space: nowrap;
  }

  &.readed {
    background-color: transparent !important;
  }

  &.email-item--sent,
  &.email-item--sending,
  &.email-item--draft {
    background: none;
    .email-item__user-name {
      font-weight: normal;
    }
    .email-item__user-role {
      background: none !important;
      padding: 0;
    }
  }

  &.email-item--draft {
    .email-item__user-name {
      font-size: 1rem;
      color: var(--color-red-04);
    }
  }
}

.attachment {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--color-gray-03);
  padding: 1px 8px;
  font-size: 12px;
  line-height: 1.35;
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0;
  }
  & > span {
    margin-left: 8px;
  }
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.stroke-star {
  &:hover > .filled {
    fill: var(--color-gray-04-hover);
  }
  &.marked > .filled {
    fill: var(--color-secondary);
  }
}

.attachment-count {
  color: var(--color-gray-07);
  line-height: 1.45;
  font-size: 14px;
  cursor: pointer;
  margin-left: 0.5rem;
}

// TODO: Test badges, remove it later!

.badge-01 {
  background-color: #c4f3f0;
  color: #1bc0b7;
}

.badge-02 {
  background-color: #e6f7b7;
  color: #9ccb18;
}

.badge-03 {
  background-color: #f7b7b7;
  color: #cb1818;
}
</style>
