<template>
  <div>
    <template v-if="emailList.length">
      <EmailAction
        @refresh="refreshEmailList"
        @delete="deleteEmail"
        @checkAll="checkAll"
        @setRead="setRead"
        @setUnread="setUnread"
        @setSpam="setSpam"
        @moveTo="moveTo"
        @selectByType="selectByType"
        @markStarred="markStarred"
        :checked="isAllChecked"
        :isCheckIndeterminate="isCheckIndeterminate"
        :view="{
          checkbox: true,
          refresh: true,
          delete: true,
          markRead: true,
          spam: true,
        }"
        :listLength="emailList.length"
        
        @prev="prevPagination"
        @next="nextPagination"
        :pageStart="emailListPageStart"
        :pageEnd="emailListPageEnd"
        :listTotal="emailListTotal"
        
      />
      <div class="email-list">
        <template v-for="email in emailList" :key="email.id">
          <EmailItem
            :email="email"
            :checked="!!emailChecked[email.id]"
            @toggleStarred="toggleStarred(email.id, $event)"
            @change="toggleChecked(email.id, $event)"
          />
        </template>
      </div>
    </template>

    <template v-else>
      <EmptyStates :title="'Mailbox is empty. Write to someone'">
        <svg width="147" height="147" viewBox="0 0 147 147" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M27.422 47.53C27.006 50.645 27 54.769 27 60.668v24.666c0 5.899.006 10.023.422 13.136.405 3.03 1.147 4.649 2.294 5.805 1.145 1.152 2.745 1.896 5.745 2.302 3.088.418 7.179.424 13.039.424h49c5.86 0 9.951-.006 13.039-.424 3-.406 4.6-1.15 5.745-2.302 1.147-1.156 1.889-2.775 2.294-5.805.416-3.113.422-7.237.422-13.136V60.667c0-5.899-.006-10.023-.422-13.136-.405-3.03-1.147-4.65-2.294-5.804-1.145-1.153-2.745-1.897-5.745-2.303C107.451 39.007 103.36 39 97.5 39h-49c-5.86 0-9.951.007-13.04.424-2.999.406-4.6 1.15-5.744 2.303-1.147 1.155-1.89 2.774-2.294 5.804zm49.85 37.782a6.048 6.048 0 01-8.544 0L39.045 55.733c-3.81-3.796-1.182-10.4 4.272-10.4h59.366c5.454 0 8.081 6.604 4.272 10.4L77.272 85.312zM24 60.667c0-11.628 0-17.442 3.588-21.055C31.176 36 36.95 36 48.5 36h49c11.549 0 17.324 0 20.912 3.612C122 43.225 122 49.04 122 60.667v24.666c0 11.628 0 17.442-3.588 21.055C114.824 110 109.049 110 97.5 110h-49c-11.55 0-17.324 0-20.912-3.612C24 102.775 24 96.961 24 85.333V60.667zm51.154 22.52a3.048 3.048 0 01-4.308 0L41.163 53.608c-1.945-1.938-.582-5.275 2.154-5.275h59.366c2.736 0 4.099 3.337 2.154 5.275L75.154 83.187z"/></svg>
      </EmptyStates>
    </template>
  </div>
</template>

<script>
import { isEmpty } from "lodash/lang";
import EmailAction from "@component/Email/EmailAction";
import EmailItem from "@component/Email/EmailItem";
import EmptyStates from "@component/EmptyStates";
import { communityGetSelected } from "@store/community.store";
import { contactGetSelected } from "@store/communityContact.store";

export default {
  name: 'Email',
  components: {
    EmailAction,
    EmailItem,
    EmptyStates,
  },
  props: {
    tag: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contactSelected: contactGetSelected(),
      community: communityGetSelected(),
      fetchEmailInterval: null,
      emailList: [
        // {
        //   id: "string",
        //   isExternal: true,
        //   subject: "Project updates letter 01",
        //   // from: USER.Profile.getEmail(),
        //   from: "petrov.e@dev.chatmail.rtelekom.sparklingtide.dev",
        //   recipient: USER.Profile.getEmail(),
        //   ccRecipient: "string",
        //   bccRecipient: "string",
        //   folder: "DRAFTS",
        //   body: "Here some minor updates for project from customer.",
        //   seen: false,
        //   ownerCommunityId: "1332ba82-11e5-425b-a5ec-e4775c7b6c30",
        //   ownerCommunityName: "Simple Dimple",
        // },
      ],
      emailListOffset: 0,
      emailListLimit:  15,
      emailListTotal:  null,
      
      emailListPageStart: null,
      emailListPageEnd: null,
      
      emailChecked: {},
    };
  },
  mounted() {
    this.fetchEmailInterval = setInterval(() => this.fetchEmail(), 30000*4);
    this.fetchEmail();
  },
  unmounted() {
    clearInterval(this.fetchEmailInterval);
  },
  computed: {
    isAllChecked() {
      return !isEmpty(this.emailChecked);
    },
    isCheckIndeterminate() {
      return Object.keys(this.emailChecked).length !== this.emailList.length;
    },
  },
  watch: {
    community: {
      handler: function () {
        this.fetchEmailReset();
      },
      deep: true,
    },
    contactSelected: {
      handler: function () {
        this.fetchEmailReset();
      },
      deep: true,
    },
    tag() {
      this.emailList = [];
      this.emailChecked = {};
      this.fetchEmailReset();
    },
  },
  methods: {
    prevPagination() {
      if(this.emailListOffset < 1) {
        return;
      }
      // reset
      this.emailList = [];
      this.emailListOffset--;
      this.emailListTotal = null;
      //
      this.fetchEmail(false);
    },
    nextPagination() {
      if( this.emailListPageEnd >= this.emailListTotal ) {
        return;
      }
      // reset
      this.emailList = [];
      this.emailListOffset++;
      this.emailListTotal = null;
      //
      this.fetchEmail(false);
    },
    
    fetchEmailReset() {
      this.emailListOffset = 0;
      this.fetchEmail();
    },
    fetchEmail() {
      
      this.emailListTotal     = null;
      this.emailListPageStart = null;
      this.emailListPageEnd   = null;
      
      const { EMAIL_FOLDER_NAME } = this.$enum;
      const folderName = EMAIL_FOLDER_NAME?.[this.tag.toUpperCase()];

      // prettier-ignore
      const params = {
        folderName: folderName,
        communityId: this.community?.id,
        seen: this.tag.toUpperCase() === "UNREAD" || undefined,
        withoutCommunity: !this.community || this.community?.name === "Personal" || undefined,
        any: this.contactSelected?.mail,
  
        offset: this.emailListOffset * this.emailListLimit,
        limit:  this.emailListLimit,
      };

      // RequestManager.Email.getAll({ tag: this.tag, role }).then(
      RequestManager.Email.getAll(params).then((res) => {
        // TODO: Проверять полученный список писем с состояниями чекбоксов на предмет наличия этих писем в ответе,
        // иначе сломается логика чекбокса (состояние Indeterminate)
        this.emailList = res.list;
        this.emailListTotal  = res.total;
  
        this.emailListPageStart = this.emailListOffset * this.emailListLimit + 1;
        this.emailListPageEnd   = this.emailListPageStart + this.emailList.length - 1;
      });
    },

    async refreshEmailList() {
      try {
        await this.fetchEmail();
        this.$dialog.notify.success("List updated", "");
      } catch (err) {
        console.error("Error: ", err);
      }
    },

    checkReset() {
      this.emailList.forEach((item) => {
        this.toggleChecked(item.id, false);
      });
    },

    checkAll(status) {
      this.emailList.forEach((item) => {
        this.toggleChecked(item.id, status);
      });
    },
    toggleChecked(emailId, status) {
      if (status) {
        this.emailChecked[emailId] = true;
      } else {
        delete this.emailChecked[emailId];
      }
    },

    toggleStarred(emailId, status) {
      if (status) {
        this.markStarred(emailId);
      } else {
        this.markUnstarred(emailId);
      }
    },

    deleteEmail() {
      const keys = Object.keys(this.emailChecked);
      if (!keys.length) {
        return;
      }

      if (this.$route.query.tag === "Trash") {
        RequestManager.Email.deleteEmailByList({ emailIds: keys }).then(() => {
          this.emailChecked = {};
          this.fetchEmail();
          this.$dialog.notify.success("Success", "Tag changed successfully!");
        });
        return;
      }

      RequestManager.Email.moveToFolder({
        emailIds: keys,
        folderName: this.$enum.EMAIL_FOLDER_NAME.TRASH,
      }).then(() => {
        this.emailChecked = {};
        this.fetchEmail();
        this.$dialog.notify.success("Success", "Tag changed successfully!");
      });
    },
    setRead() {
      const keys = Object.keys(this.emailChecked);
      if (!keys.length) {
        return;
      }

      RequestManager.Email.setRead({ emailIds: keys }).then(() => {
        this.emailChecked = {};
        this.fetchEmail();
        this.$dialog.notify.success("Success", "Read status changed successfully!");
      });
    },

    setUnread() {
      const keys = Object.keys(this.emailChecked);
      if (!keys.length) {
        return;
      }

      RequestManager.Email.setUnread({ emailIds: keys }).then(() => {
        this.emailChecked = {};
        this.fetchEmail();
        this.$dialog.notify.success("Success", "Read status changed successfully!");
      });
    },

    setSpam() {
      const keys = Object.keys(this.emailChecked);
      if (!keys.length) {
        return;
      }

      RequestManager.Email.moveToFolder({
        emailIds: keys,
        folderName: this.$enum.EMAIL_FOLDER_NAME.SPAM,
      }).then(() => {
        this.emailChecked = {};
        this.fetchEmail();
        this.$dialog.notify.success("Success", "Tag changed successfully!");
      });
    },

    moveTo(folderName) {
      const keys = Object.keys(this.emailChecked);
      if (!keys.length) {
        return;
      }

      RequestManager.Email.moveToFolder({ emailIds: keys, folderName }).then(
        () => {
          this.emailChecked = {};
          this.fetchEmail();
          this.$dialog.notify.success("Success", "Tag changed successfully!");
        }
      );
    },

    selectByType(type) {
      this.checkReset();
      switch (type) {
        case "all":
          this.checkAll(true);
          break;
        case "read":
          {
            this.emailList.forEach((email) => {
              if (email.seen) {
                this.toggleChecked(email.id, true);
              }
            });
          }
          break;
        case "unread":
          {
            this.emailList.forEach((email) => {
              if (!email.seen) {
                this.toggleChecked(email.id, true);
              }
            });
          }
          break;
        case "starred":
          {
            this.emailList.forEach((email) => {
              if (email.isStarred) {
                this.toggleChecked(email.id, true);
              }
            });
          }
          break;
        case "unstarred": {
          this.emailList.forEach((email) => {
            if (!email.isStarred) {
              this.toggleChecked(email.id, true);
            }
          });
        }
      }
    },

    markStarred(emailId) {
      const keys = Object.keys(this.emailChecked);
      if (!keys.length && !emailId) {
        return;
      }

      let listId = [];

      if (keys.length) {
        listId = keys;
      } else {
        listId.push(emailId);
      }

      RequestManager.Email.setStarred({ listId: listId }).then(() => {
        this.emailChecked = {};
        this.fetchEmail();
        this.$dialog.notify.success("Success", "Starred status changed successfully!");
      });
    },

    markUnstarred(emailId) {
      const keys = Object.keys(this.emailChecked);
      if (!keys.length && !emailId) {
        return;
      }

      let listId = [];

      if (keys.length) {
        listId = keys;
      } else {
        listId.push(emailId);
      }

      RequestManager.Email.setUnstarred({ listId: listId }).then(() => {
        this.emailChecked = {};
        this.fetchEmail();
        this.$dialog.notify.success("Success", "Starred status changed successfully!");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}
</style>
